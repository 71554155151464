
import RcTableViewModel from "../../../../../../shared/table/RcTableViewModel";
import DetailsDisplayViewUtils from "./CardUpdateViewUtils";
import StringUtils from '@/utils/StringUtils.js';

export default class CardUpdateViewModel extends RcTableViewModel {
    
  constructor(panel, cardId) {
    super(panel);
    this._total = 0;
    this._error = null;
    this._items = [];
    this._cardId = cardId;
    this._card = null;
    this._address = null;
    this._cardValues = {};
  }

  start() {
    super.start();
  }

  card() {
    return this._card;
  }

  address() {
    return this._address;
  }

  isAdmin() {
    return this.panel().isAdmin;
  }

  withValue(valueData) {
    this.panel().showMissingRequirements = false;
    super.withValue(valueData);
    if (valueData["key"] === "Card") {
      this._cardValues[valueData["field"]] =valueData["value"];
    }
    if (valueData["key"] === "Address") {
      this._address.put(valueData["field"], valueData["value"]);
    }

    this.logJson(this._cardValues);
    this.logJson(this._address.data);
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }

    if (buttonData["field"] === "Save") {
      this.save();
    }
  }

  buildRowData(tableData) {
    var utils = new DetailsDisplayViewUtils(this, tableData);
    utils.start();
    return this;
  }

  error() {
    return this._error;
  }

  loadItems() {
    this._card = this.domain().invoices().cards()
      .findById(this._cardId).copy();
    this._card
      .withNumber("")
      .withCvc("")
      .withYear("")
      .withMonth("")

    this._cardValues["ccName"] = this.card().name();
    this._cardValues["ccNumber"] = this.card().number();
    this._cardValues["ccCvc"] = this.card().cvc();
    this._cardValues["ccYear"] = this.card().year();
    this._cardValues["ccMonth"] = this.card().month();
      
    this._address = this._card.address().find().copy();

    this
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
  }

  areRequirementsMet() {
    var met = true;
    
    if (met && StringUtils.isEmpty(this._cardValues["ccName"])) {
      met = false
    }
    if (met && StringUtils.isEmpty(this._cardValues["ccNumber"])) {
      met = false
    }
    if (met && StringUtils.isEmpty(this._cardValues["ccYear"])) {
      met = false
    }
    if (met && StringUtils.isEmpty(this._cardValues["ccMonth"])) {
      met = false
    }

    var address = this._address;
    if (met && StringUtils.isEmpty(address.street())) {
      met = false;
    }
    if (met && StringUtils.isEmpty(address.city())) {
      met = false;
    }
    if (met && StringUtils.isEmpty(address.country())) {
      met = false;
    }
    if (met && StringUtils.isEmpty(address.province())) {
      met = false;
    }
    if (met && StringUtils.isEmpty(address.postal())) {
      met = false;
    }
    
    return met;
  }

  save() {
    const addressData = this._address.data;
    const cardData = this._cardValues;
    if (!this.areRequirementsMet()) {
      this.panel().showMissingRequirements = true;
      return;
    }
    var event = this.domain().events().cards()
      .save(cardData, addressData);

    this
      .withState("saving")
      .clearAndBuildTableData()
      .done();

    var context = {};
    context["model"] = this;
    if (!event) {
      return;
    }
    event.send(this.saveListener, context);
  }

  saveListener(response, context) {
    const model = context.model;
    if (response.hasError()) {
      model._error = response.error();
      model
        .withStateContentLoaded()
        .clearAndBuildTableData()
        .done();
    } else {
      if (model) {
        var buttonData = {};
        buttonData["field"] = "DetailsClose";
        buttonData["action"] = "reload";
        model.panel().withButtonPressed(buttonData);
      }
    }
  }
}